/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

 require('./bootstrap');

//  import VueToast from 'vue-toast-notification';
//  import 'vue-toast-notification/dist/theme-sugar.css';
 
//  import Vue from 'vue';
//  window.Vue = Vue;
//  Vue.use(VueToast, {
//      duration: 5000,
//  });
 